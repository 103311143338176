<template>
	<div>
		<UsuarioDialog @closeModal="loadUsuarios" :id="id" @dialogClose="dialogClose" :dialog="dialog" />
		<v-card>
			<v-card-title> <v-icon left color="primary">lock</v-icon>Cadastro de Usuários </v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" md="9">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Pesquisar pelo Nome ou email"
							single-line
							hide-details
						></v-text-field>
					</v-col>
					<v-col cols="12" md="3">
						<v-btn
							v-if="manterUsuarios"
							class="white--text"
							color="primary"
							elevation="9"
							width="100%"
							@click="cadastrarUsuario()"
							large
						>
							<v-icon left> mdi-plus </v-icon>
							Cadastrar Usuário
						</v-btn>
					</v-col>
				</v-row>
				<v-row class="mt-0">
					<v-col cols="12" sm="6" md="1">
						<v-checkbox v-model="desativados" label="Desativados?"></v-checkbox>
					</v-col>
				</v-row>
				<v-data-table
					:headers="headers"
					:items="usuarios"
					class="elevation-1"
					:loading="loading"
					disable-pagination
					hide-default-footer
					@click:row="usuarioSelected"
				>
					<template v-slot:[`item.ativo`]="{ item }">
						<v-icon color="success" v-if="item.ativo">check_circle_outline</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import UsuarioDialog from '../components/usuarios/UsuarioDialog'

export default {
	components: { UsuarioDialog },
	computed: {
		...mapGetters(['userAdmin', 'userMaster', 'manterUsuarios']),
	},
	data: function () {
		return {
			search: '',
			usuarios: [],
			headers: [
				{ text: 'Nome', value: 'nome' },
				{ text: 'E-mail', value: 'email' },
				{ text: 'Cidade', value: 'cidade' },
				{ text: 'Grupo', value: 'hierarquia.nome' },
				{ text: 'Ativo', value: 'ativo' },
			],
			loading: false,
			dialog: false,
			id: '',
			desativados: false,
		}
	},
	methods: {
		async loadUsuarios() {
			try {
				this.loading = true
				const response = await this.axios.get('/usuarios', {
					params: { search: this.search, desativados: this.desativados },
				})
				this.usuarios = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		usuarioSelected(usuario) {
			this.dialog = true
			this.id = usuario.id
		},
		cadastrarUsuario() {
			this.dialog = true
		},
		dialogClose() {
			this.dialog = false
		},
	},
	mounted() {
		this.loadUsuarios()
	},
	watch: {
		search() {
			this.loadUsuarios()
		},
		desativados(){
			this.loadUsuarios()
		}
	},
}
</script>